import React from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Option from '../singleProperty/UploadOptions';
import { SegmentUser } from '../../utils/segmentTracker';
import SingleForm from '../singleProperty';
import SPUEditPage from '../singleProperty/propertyEditForm';
import PropertyUploadButton from '../PropertyUploadButton';
import { UserTrackDetails } from '../Modal';
import InvitePage from '../invite';

const queryClient = new QueryClient();

const Uploader: React.FC<{ user: SegmentUser; isAdmin: boolean, isPropertyTransferAdmin: boolean }> = ({ user, isAdmin, isPropertyTransferAdmin }) => {
  return (
    <QueryClientProvider client={queryClient}>
      <BrowserRouter basename="admin/properties/add">
        <Routes>
          <Route path="new" element={<Option user={user} />} />
          <Route path="single" element={<SingleForm user={user} />} />
          <Route path="single/:propertyId" element={<SPUEditPage user={user} />} />
          <Route path="invite" element={<InvitePage />} />
          <Route
            path="bulk"
            element={<PropertyUploadButton showModal={true} user={user as UserTrackDetails} isAdmin={isAdmin} isPropertyTransferAdmin={isPropertyTransferAdmin} />}
          />
        </Routes>
      </BrowserRouter>
    </QueryClientProvider>
  );
};

export default Uploader;
